import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import imgAndroid from "../images/android-icon.png"
import imgAdobeXd from "../images/adobe-xd-icon.png"
import imgGatsby from "../images/gatsby-icon.png"
import imgFigma from "../images/figma-icon.png"

export default function Portfolio() {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 6
        ) {
        edges {
          node {
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              path
              title
              imageUrl {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              android
              adobeXd
              figma
              gatsby
            }
          }
        }
      }
    }
  `)

  return (
    <div className="portfolio__container">
      <section className="menu section bd-container" id="portfolio">
        <div className="vertical-line">
          <h3 className="section-subtitle about__initial about__subtitle">Portfolio  <Link to="/portfolio" className="portfolio__pages">See All</Link></h3>
        </div>

        <div className="menu__container bd-grid">
          {data.allMarkdownRemark.edges.map(({ node }, i) => (
            <Link to={node.frontmatter.path}>
              <div key={i} className="menu__content" data-sal="slide-down" data-sal-delay="200" data-sal-duration="400" data-sal-easing="ease">
                <Img fluid={node.frontmatter.imageUrl.childImageSharp.fluid} className="menu__img" />
                <h3 className="menu__name">{node.frontmatter.title}</h3>
                <span className="menu__detail left">{node.excerpt}</span>
                <div>
                  <p className={node.frontmatter.android ? 'portfoliolist__true' : 'portfoliolist__false'}><img src={imgAndroid} className="portfoliolist__android_icon" alt="" />Android</p>
                  <p className={node.frontmatter.gatsby ? 'portfoliolist__true' : 'portfoliolist__false'}><img src={imgGatsby} className="portfoliolist__adobe_icon" alt="" />Gatsby</p>
                  <p className={node.frontmatter.adobeXd ? 'portfoliolist__true' : 'portfoliolist__false'}><img src={imgAdobeXd} className="portfoliolist__adobe_icon" alt="" />Adobe Xd</p>
                  <p className={node.frontmatter.figma ? 'portfoliolist__true' : 'portfoliolist__false'}><img src={imgFigma} className="portfoliolist__adobe_icon" alt="" />Figma</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </section>
    </div>
  )
}